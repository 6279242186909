import { Stack, Typography } from "@mui/material";
import { useState, useRef, useEffect } from "react";

export const AutoCompleteGroup = ({
  label,
  items,
  onSelect = () => {},
  onTextChange = () => {},
  fullWidth,
  textValue,
  disabled = false,
}) => {

  const [inputAValue, setInputAValue] = useState(""); // Value for Input A
  const [inputBValue, setInputBValue] = useState(""); // Value for Input B (search box in dropdown)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown visibility state

  const dropdownRef = useRef(null); // Ref for detecting clicks outside

  useEffect(() => {
    setInputAValue(textValue); // Set Input A to the initial text value
  }, [textValue])

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputAClick = () => {
    setIsDropdownOpen(true); // Open dropdown
    setInputBValue(""); // Reset search box (Input B)
  };

  const handleSelect = (value, item) => {
    setInputAValue(value); // Set Input A to the selected value
    setIsDropdownOpen(false); // Close dropdown
    onTextChange(item.label);
    onSelect(item);
  };

  const handleInputBChange = (event) => {
    setInputBValue(event.target.value); // Update search box (Input B)
  };

  const filteredItems = items.filter((item) => {
      if (item.label == undefined) return null
      return item.label.toLowerCase().includes(inputBValue.toLowerCase())
    }
  );

  return (
    <Stack
      className={`min-w-fit ${fullWidth && "w-full"} z-10 relative`}
      spacing="4px"
      alignItems="flex-start"
    >
      <Typography className="text-[#505155] text-[11px] capitalize">
        {label}
      </Typography>
      {/* Input A */}
      <input
        type="text"
        value={inputAValue}
        onClick={handleInputAClick}
        readOnly
        placeholder="Click to select..."
        className="border-[1.5px] border-[#D9E1E5] rounded-[12px] px-[12px] py-[8px] bg-white text-[#9EAAB1] w-full text-black z-10 cursor-pointer"
        disabled={disabled}
      />
      {/* Autocomplete Dropdown */}
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          style={{
            maxHeight: "250px",
            borderRadius: "3px",
            boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
            background: "rgba(255, 255, 255)",
            padding: "2px 0",
            fontSize: "90%",
            position: "absolute",
            top: "60px",
            left: 0,
            overflow: "auto",
            zIndex: 1000,
            width: "100%"
          }}
        >
          {/* Input B */}
          <div className="px-[12px] py-[8px]">
            <input
              type="text"
              value={inputBValue}
              onChange={handleInputBChange}
              placeholder="Search..."
              className="w-full border-[1.5px] border-[#D9E1E5] rounded-[12px] px-[8px] py-[6px] text-black bg-white capitalize"
              disabled={disabled}
            />
          </div>
          {/* Filtered Items */}
          {filteredItems.map((item, index) => (
            <div
              key={index}
              onClick={() => handleSelect(item.label, item)}
              style={{
                padding: "8px 12px",
                background: "#fff",
                cursor: "pointer",
                borderBottom: "1px solid #eee"
              }}
              className="hover:bg-gray-200"
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </Stack>
  );
};
